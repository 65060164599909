/* Full-page gradient background */
.gradient-background {
    height: 100vh;
    width: 100vw;
    background: linear-gradient(180deg, #2C3E50, #4CA1AF);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding-top: 80px; /* Adjust to match the height of your fixed menu */
    overflow-y: auto;
    padding: 20px;
    box-sizing: border-box;
  }
  
  /* Card container styling for mobile */
  .card-container {
    background: rgba(255, 255, 255, 0.9);
    border-radius: 15px;
    padding: 20px;
    margin: 20px 0;
    width: 90%; /* Mobile width */
    max-width: 400px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Card content styling */
  .card-content h2 {
    margin: 0;
    font-size: 24px;
  }
  
  .card-content p {
    font-size: 16px;
    color: #333;
  }
  
  .img-container img {
    width: 100%;
    border-radius: 10px;
    margin-top: 10px;
    object-fit: cover;
  }
  
  /* Larger card styling for laptop and desktop screens */
  @media (min-width: 768px) {
    .card-container {
      width: 70%; /* Larger width for tablets and laptops */
      max-width: 600px; /* Maximum width limit */
      padding: 30px; /* Increase padding for larger screens */
    }
  
    .card-content h2 {
      font-size: 28px; /* Increase text size */
    }
  
    .card-content p {
      font-size: 18px; /* Increase text size */
    }
  }
  
  @media (min-width: 1024px) {
    .card-container {
      width: 60%; /* Even larger width for desktops */
      max-width: 700px; /* Larger max width for desktop screens */
    }
  }

  /* Slider container styling */
.slider-container {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 10px;
    position: relative;
  }
  
  /* Custom arrows */
  .slick-prev,
  .slick-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background for contrast */
    color: white;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    z-index: 10; /* Higher z-index to ensure visibility */
    opacity: 1; /* Make sure they are fully opaque */
  }
  
  /* Positioning arrows inside the slider container */
  .slick-prev {
    left: 10px; /* Adjust to move closer or farther from the image */
  }
  
  .slick-next {
    right: 10px;
  }
  
  /* Carousel image container */
.slider-image {
    height: 400px; /* Set a fixed height for consistency */
    width: 100%;
    object-fit: cover; /* Scale the image to cover the entire container */
    object-position: center; /* Center the image within the container */
    border-radius: 10px;
  }
  