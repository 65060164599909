@media screen and (max-width: 960px) {
    .container-sport-kinder {
        background-image: url('../static/images/pyramid.jpg');
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-left: 0%;
        margin-right: 0%;
      }
          .container-sport-bodyshape {
        background-image: url('../static/images/bodyshape.jpg');
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-left: 0%;
        margin-right: 0%;
      }
          .container-sport-football {
        background-image: url('../static/images/football.jpg');
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-left: 0%;
        margin-right: 0%;
      }
          .container-sport-seniorengym {
        background-image: url('../static/images/seniorgym.jpg');
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-left: 0%;
        margin-right: 0%;
      }
      
    .address {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }
    .section{
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }
    .left_col{
        padding:20px;
        padding-right:20px;
        display:inline-block;
        vertical-align:top;
        max-width:900px;
        position:relative;
        min-height:100px;
    }
    
    .right_col{
        max-width:900px;
        display:inline-block;
        padding:20px;
        min-height:100px;
        vertical-align: baseline;
    }

    .page_block  {
        border: 1px solid #FFFFFF;
        background:#FFF;
        border: 1px solid rgba(252, 243, 228, 0.918);
        background: rgba(252, 243, 228, 0.918);
        overflow:hidden;
        border-radius:1px;
        padding: 30px;
    }

    .module{
        display: block;
        width: auto;
        margin-top: 10px;
    }

    .dark {
        background-color: #181719;
        color: #FFF;
        border: #181719;
    }
    .map {
        margin: 0;
        margin-bottom: 20px;
        padding: 0;
        width: auto;
    }
    .left_col img{
        max-width: 100%;
        height: auto;        
    }

    .fit {
        padding: 0;
        border: 0;
    }

    .title-category {
        font-size: 28x;
        font-family: Impact, Arial, sans-serif;
        letter-spacing: 1px;
    }
    .title-category-dark{
        font-size: 28x;
        font-family: Impact, Arial, sans-serif;
        letter-spacing: 1px;
        color: rgba(252, 243, 228, 0.918);
    }

    .location_icon {
        display:inline-block;
        margin-right:30px;
        float:left;
    }
    .location_info{
        vertical-align:top;
        overflow:hidden;
        min-height:50px;
    }
        .location{
        min-height:60px;
        vertical-align:middle;
        padding:25px;
    }
    .location_info a{
        text-decoration:none;
        color:#000;
    }
    
    .location_title{
        font-weight: 600;
        padding-bottom:3px;
        font-size:18px;
    }
    
    .right_col img{
        max-width: 100%;
        height: auto;        
    }

    .agenda-title{
        font-weight: 600;
        padding-left:20px;
        /* padding-top:20px; */
        padding-bottom:3px;
        font-size:18px;
    }

    .agenda-kurs{
        font-weight: 600;
        padding-left:20px;
        padding-bottom:3px;
        font-size:16px;
        font-style: italic;
    }

    .agenda-hour{
        font-weight: 400;
        padding-left:20px;
        padding-bottom:6px;
        font-size:14px;
        color: gray;
    }
    
    .p-ort {
        margin-bottom: 0;
    }
}


@media screen and (min-width: 960px) and (max-width: 1240px) {
    .container-sport-kinder {
        background-image: url('../static/images/pyramid.jpg');
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-left: 0%;
        margin-right: 0%;
      }
      .container-sport-bodyshape {
        background-image: url('../static/images/bodyshape.jpg');
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-left: 0%;
        margin-right: 0%;
      }
      .container-sport-football {
        background-image: url('../static/images/football.jpg');
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-left: 0%;
        margin-right: 0%;
      }
      .container-sport-seniorengym {
        background-image: url('../static/images/seniorgym.jpg');
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-left: 0%;
        margin-right: 0%;
      }
              
    .address {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }
    .section{
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }
    .left_col{
        padding:20px;
        padding-right:0px;
        display:inline-block;
        vertical-align:top;
        width:630px;
        position:relative;
        min-height:100px;
    }
    
    .right_col{
        width:335px;
        display:inline-block;
        padding:20px;
        min-height:100px;
        vertical-align: baseline;
    }

    .page_block  {
        border: 1px solid #FFFFFF;
        background:#FFF;
        border: 1px solid rgba(252, 243, 228, 0.918);
        background: rgba(252, 243, 228, 0.918);
        overflow:hidden;
        /* border-radius:1px; */
        padding: 30px;
    }

    .module{
        position:relative;
        margin-bottom:20px;
        /* border-radius:10px; */
    }

    .dark {
        background-color: #181719;
        color: #FFF;
        border: #181719;
    }
    .map {
        margin: 0;
        margin-bottom: 20px;
        padding: 0;
    }
    .left_col img{
        max-width: 100%;
        height: auto;        
    }

    .fit {
        padding: 0;
        border: 0;
    }

    .title-category {
        font-size: 28x;
        font-family: Impact, Arial, sans-serif;
        letter-spacing: 1px;
    }
    .title-category-dark{
        font-size: 28x;
        font-family: Impact, Arial, sans-serif;
        letter-spacing: 1px;
        color: rgba(252, 243, 228, 0.918);
    }

    .location_icon {
        display:inline-block;
        margin-right:30px;
        float:left;
    }
    .location_info{
        vertical-align:top;
        overflow:hidden;
        min-height:50px;
    }
        .location{
        min-height:60px;
        vertical-align:middle;
        padding:25px;
    }
    .location_info a{
        text-decoration:none;
        color:#000;
    }
    
    .location_title{
        font-weight: 600;
        padding-bottom:3px;
        font-size:18px;
    }

    .right_col img{
        max-width: 100%;
        height: auto;        
    }

    .agenda-title{
        font-weight: 600;
        padding-left:20px;
        /* padding-top:20px; */
        padding-bottom:3px;
        font-size:18px;
    }

    .agenda-kurs{
        font-weight: 600;
        padding-left:20px;
        padding-bottom:3px;
        font-size:16px;
        font-style: italic;
    }

    .agenda-hour{
        font-weight: 400;
        padding-left:20px;
        padding-bottom:6px;
        font-size:14px;
        color: gray;
    }
    .p-ort {
        margin-bottom: 0;
    }

}
@media screen and (min-width: 1240px) {

    .container-sport-kinder {
        background-image: url('../static/images/pyramid.jpg');
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-left: 0%;
        margin-right: 0%;
      }
      .container-sport-bodyshape {
        background-image: url('../static/images/bodyshape.jpg');
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-left: 0%;
        margin-right: 0%;
      }
      .container-sport-football {
        background-image: url('../static/images/football.jpg');
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-left: 0%;
        margin-right: 0%;
      }
      .container-sport-seniorengym {
        background-image: url('../static/images/seniorgym.jpg');
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-left: 0%;
        margin-right: 0%;
      }
              
    .address {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }
    .section{
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }
    .left_col{
        padding:20px;
        padding-right:0px;
        padding-top: 40px;
        display:inline-block;
        vertical-align:top;
        width:800px;
        position:relative;
        min-height:100px;
    }
    
    .right_col{
        width:500px;
        display:inline-block;
        padding:20px;
        padding-top: 40px;
        min-height:100px;
        vertical-align: baseline;
    }

    .page_block  {
        border: 1px solid #FFFFFF;
        background:#FFF;
        border: 1px solid rgba(252, 243, 228, 0.918);
        background: rgba(252, 243, 228, 0.918);
        overflow:hidden;
        /* border-radius:1px; */
        padding: 30px;
    }

    .block-img  {
        overflow:hidden;
        padding: 20px;
        padding-right: 80px;
    }

    .module{
        position:relative;
        margin-bottom:20px;
        /* border-radius:10px; */
    }

    .dark {
        background-color: #181719;
        color: #FFF;
        border: #181719;
    }
    .map {
        margin: 0;
        margin-bottom: 20px;
        padding: 0;
    }
    .left_col img{
        max-width: 100%;
        height: auto;        
    }

    .right_col img{
        max-width: 70%;
        height: auto;        
    }

    .fit {
        padding: 0;
        border: 0;
    }

    .title-category {
        font-size: 28x;
        font-family: Impact, Arial, sans-serif;
        letter-spacing: 1px;
    }
    .title-category-dark{
        font-size: 28x;
        font-family: Impact, Arial, sans-serif;
        letter-spacing: 1px;
        color: rgba(252, 243, 228, 0.918);
    }

    .location_icon {
        display:inline-block;
        margin-right:30px;
        float:left;
    }
    .location_info{
        vertical-align:top;
        overflow:hidden;
        min-height:50px;
    }
        .location{
        min-height:60px;
        vertical-align:middle;
        padding:25px;
    }
    .location_info a{
        text-decoration:none;
        color:#000;
    }
    
    .location_title{
        font-weight: 600;
        padding-bottom:3px;
        font-size:18px;
    }
    
    .agenda-title{
        font-weight: 600;
        padding-left:20px;
        /* padding-top:20px; */
        padding-bottom:3px;
        font-size:18px;
    }

    .agenda-kurs{
        font-weight: 600;
        padding-left:20px;
        padding-bottom:3px;
        font-size:16px;
        font-style: italic;
    }

    .agenda-hour{
        font-weight: 400;
        padding-left:20px;
        padding-bottom:6px;
        font-size:14px;
        color: gray;
    }
    .p-ort {
        margin-bottom: 0;
    }


}