.rbc-event {
    min-height: 20px;   /* Set minimum height of the event */
    height: 30px;       /* Explicitly set height */
    line-height: 30px;  /* Align text vertically */
    align-items: center;
    padding: 2px 5px;   /* Adjust padding */
    font-size: 12px;    /* Decrease font size */
  }
@media (max-width: 600px) {
    .rbc-event {
      min-height: 16px; /* Further reduce height for mobile */
      height: 16px; /* Further reduce height for mobile */
      line-height: 16px; /* Adjust line height */
      align-items: center;
      font-size: 10px;   /* Smaller font size */
      padding: 1px 3px;  /* Adjust padding for smaller screens */
    }
  }
  