/* Sport.css */

.services-container {
    padding-top: 20px; /* Adjust according to your AppBar height */
    padding-bottom: 20px; /* Adjust according to your AppBar height */
  }
  
  .services-grid {
    display: grid; /* Change from flex to grid for better responsiveness */
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr)); /* Responsive grid layout */
    gap: 20px; /* Adjust spacing between tiles */
  }
  @media (max-width: 600px) {
      .services-grid {
          grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Adjust column width for smaller screens */
      }
      }
  
  .service-tile {
    /* Remove width and height properties */
    width: 100%;
    height: 400px; /* Adjust the height as needed */
    }
  .service-image {
    width: 100%;
    height: 100%; /* Ensure images fill their container */
    background-size: cover; /* Ensure the background image covers the entire container */
    background-position: center; /* Center the background image */
    display: flex;
    justify-content: center;
    align-items: center;  
    filter: brightness(0.7);
    /* border-radius: 8px; */
    cursor: pointer; /* Add pointer cursor for better UX */
  }
  
  .football {
    background-image: url('../static/images/football.jpg'); /* Replace with actual image URL */
  }
  
  .kinderturnen {
    background-image: url('../static/images/pyramid.jpg'); /* Replace with actual image URL */
  }
  
  .bodyshape {
    background-image: url('../static/images/bodyshape.jpg'); /* Replace with actual image URL */
  }
  
  .seniorgym {
    background-image: url('../static/images/seniorgym.jpg'); /* Replace with actual image URL */
  }
  
  .service-text {
    color: white; /* Adjust text color as needed */
    font-size: 24px; /* Adjust text size as needed */
    text-align: center;
    padding: 10px; /* Adjust padding as needed */
    border-bottom: 4px solid white;
  }
  a {
    text-decoration: none;
  }
  .service-image:hover {
    filter: brightness(1);
  }
  
  .service-image:hover .service-text {
    border: 4px solid white;
  }
  
  .modal-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 90%;
    max-width: 1000px; /* Set maximum width if needed */
    padding: 20px;
    /* border-radius: 8px; */
  }
  