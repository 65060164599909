body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* display: flex; */
  /* flex-direction: column; */
  align-items: center;
  /* justify-content: center; */
  min-height: 100vh;
  background-color: #f0f0f0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.container {
  width: 50%;
  max-width: 600px;
  padding: 20px;
  /* background-color: #fff; */
}

@media (max-width: 768px) {
  .container {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .navbar {
    width: 100%;
  }
}

/* .center-div {
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  text-align: left;
  padding: 8px;
} */

/* .wide-div {
  margin-left: auto;
  margin-right: auto;
  width: 75%;
  text-align: left;
  padding: 8px;
} */

/* .wide-left-div {
  margin-left: 0px;
  margin-right: auto;
  width: 75%;
  text-align: left;
  padding: 8px;
} */
/* 
.half-left-div {
  margin-left: 0px;
  margin-right: auto;
  width: 50%;
  text-align: left;
  padding: 8px;
} */

/* .div-description {
  text-align: justify;
  text-justify: inter-word;
} */

/* Blog style */
.leftcolumn {   
  float: left;
  width: 73%;
}
/* Right column */
.rightcolumn {
  float: left;
  width: 27%;
  padding-left: 20px;
  /* overflow: hidden */

}

@media (max-width: 768px) {
  .rightcolumn {
    padding-left: 0px;
  }
}

.blog {
  background-color: #f1f1f1;
  margin-top: 20px;
}

.blogitem {
  background-color: white;
  padding: 20px;
  margin-top: 20px;
}

.p-blog {
  color: rgb(75, 74, 74);
  /* padding: 15px; */
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.button-form {
  margin-top: 1em;
}

/* Animation */
.card-animation {
  width: 70%;
  height: 200px;
  display: flex;
  /* flex-wrap: wrap; */
  color: white;
    transform-origin: 10% 60%;
  }
  
  .img-animation {
    height: 50%;
    width: auto;
  }
  @media (max-width: 768px) {
    .card-animation {
      width: 300px;
    }
  }

.break {
  padding-top: 10%;
  padding-left: 5%;
  flex-basis: 100%;
  align-items: center;
  height: 0;
}

.card-container-animation {
  /* overflow: hidden; */
  height: 800px;

  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  position: relative;
  padding-top: 0px;
  margin-bottom: 0;
}

.splash {
  /* padding-bottom: 200px; */
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  clip-path: path(
    "M 0 0 L 2000 0 L 2000 800 L 0 800 Z"
    );
  }
  /* "M 0 303.5 C 0 292.454 8.995 285.101 20 283.5 L 460 219.5 C 470.085 218.033 480 228.454 480 239.5 L 500 430 C 500 441.046 491.046 450 480 450 L 20 450 C 8.954 450 0 441.046 0 430 Z" */

.root-animation {
  /* margin: 100px auto; */
  max-width: 2000px;
  /* padding-bottom: 200px; */
}

