.teacher-title {
    font-weight: 600;
    padding-bottom:3px;
    font-size:18px;
    padding-left: 20px;
}
.teacher-phone {
    padding-left: 20px;

}
.teacher-email {
   padding-left: 20px;

}
.teacher-description {
    /* padding-top: 20px; */
    padding-left: 20px;

}

.links-sport {
    padding-left: 0px;
    margin: 0;
}
.desc-title {
    font-weight: 600;
    padding-bottom:3px;
    font-size:18px;
    padding-left: 0px;
}

.teacher-additional {
    font-weight: 600;
    font-size:18px;
}